
/*.inputText{
    width: 100%!important
}

.backGround {
    background-image: url("../../assets/img/backgroundlogo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}*/
.form-login {
    width: 100%;
    height: 100%;
    /* Firefox */
    display: -moz-box;
    -moz-box-pack: center;
    -moz-box-align: center;
    /* Safari and Chrome */
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    /* W3C */
    display: box;
    box-pack: center;
    box-align: center;
    height: 100vh !important;
    position: relative;
}

.form-logo {
    margin: 0 auto;
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
    /*border: 1px solid #ccc;*/
    border-radius: 100%;
    min-width: 100px;
    min-height: 100px;
    box-shadow: 0px 4px 4px 0px #ccc !important;
}

.form-title {
    font-size: 18px; 
    color: #E61E2B;
    font-weight: 600;
    text-shadow: 1px 0px #ccc
}

.login-layout {
    /*padding-top: 25vh;*/
    min-width: 300px;
    width: 300px;
    /* top: 35vh!important */
}

.btn-login {
    background-color: #E61E2B !important
}

.form-version {
    position: absolute !important;
    bottom: 15px;
    /*width: 100%;*/
    /*height: 50px;*/
    /*border: 1px solid red;*/
    text-align:right;
    right:15px
}