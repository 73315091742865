.legend {
    display: flex;
    list-style: none;
  }
  
  .legend-item {
    padding-left: 5px;
    padding-right: 5px;
  }

  .chart-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #fff;
    color: #fff;
}

.chart-table.table-bordered th, .chart-table.table-bordered td{
  border: 1px solid #fff;
}