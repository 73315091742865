
html, body{
    /* font-family: Arial, Helvetica, sans-serif; */
}

h4, label, div{
    /* font-family: Arial, Helvetica, sans-serif; */
}

.card-title{
    font-weight: bold;
    color: #EE1C25!important;
}

.img-send-store{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 200px;
    border: 1px solid #ccc;
}

.title-image{
    /* top: 20px; */
    width: 100%;
    text-align: center;
    /* position: absolute; */
    /* margin-top: -30px; */
    left: 0;
    font-size: 12px;
    color: #2c3e50 !important;
    font-weight: 600;
    /* text-shadow: 1px 1px #000; */
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;

}

/* .line-clamp:hover{
    -webkit-line-clamp: none;
    overflow: unset;
    height: 100px;
} */

.p-calendar > input{
    width: 100%;
}

#root{
    height: 100%!important;
}

.p-inputtext{
    font-family: Arial, sans-serif!important;
}

.body .p-paginator .p-dropdown .p-dropdown-trigger, body .p-paginator .p-dropdown .p-dropdown-label{
    height: 32px!important;
}

.p-fieldset, .p-fieldset .p-fieldset-legend{
    width: auto;
}

.p-fieldset-legend-text{
    font-family: Arial, sans-serif!important;
}

.form-undo{
    position:absolute; 
    top: -25px;
    right: 25px;
    padding: 5px;
    border: 1px solid #ccc;
    background-color:#fff;
    border-radius: 5px;
}

@-moz-document url-prefix() {
    .form-undo{
        top: -55px;
    }
}


.btn-account {
    width: 38px;
    height: 38px;
    background-color: #de1f27 !important;
    border:1px solid #fff!important;
    border-radius: 100%!important
}

.div-img-account {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: contain !important;
}

.img-account {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
}

.p-setting-account {
    width: 340px;
    margin-top: 15px;
    left: unset;
    right: 15px!important;
}

.p-account-setting-body {
    margin-top: 0px !important;
}

.layout-topbar .layout-topbar-icons button {
    margin-left: 15px;
}

.p-overlaypanel-content{
    padding: 7px!important
}

.btn-account{
    width: 34px;
    height: 34px;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a{
    border-color: #ccc;
}


.p-menuitem-text{
    font-family: Arial, sans-serif!important;
}

.p-card-title{
    text-align: center;
    color: red;
    margin-bottom: 30px;
}

.p-fieldset-legend-text{
    text-transform: uppercase;
}

.p-no-padding{
    padding: 0px!important;
}

.card-event-end{
    border-radius: 0px 50px;
    border: 3px solid red;
}