@charset "UTF-8";
#root,
html,
body,
.body-admin {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
  #root body,
  html body,
  body body,
  .body-admin body {
    /*Thanh menu*/
    /*Chỉnh thông tin account đăng nhập*/
    /*fieldset*/
    /*Lưới*/
    /*danh sách hoạt động*/
    /*danh sách thông tin */ }
    #root body .nav-cocacola,
    html body .nav-cocacola,
    body body .nav-cocacola,
    .body-admin body .nav-cocacola {
      background-color: #de1f27 !important; }
      #root body .nav-cocacola .nav-menu-logo,
      html body .nav-cocacola .nav-menu-logo,
      body body .nav-cocacola .nav-menu-logo,
      .body-admin body .nav-cocacola .nav-menu-logo {
        height: 24px;
        object-fit: contain; }
      #root body .nav-cocacola .nav-item-coca,
      html body .nav-cocacola .nav-item-coca,
      body body .nav-cocacola .nav-item-coca,
      .body-admin body .nav-cocacola .nav-item-coca {
        color: #fff !important;
        padding: 2.5px 10px;
        font-size: 14px; }
      #root body .nav-cocacola .btn-account,
      html body .nav-cocacola .btn-account,
      body body .nav-cocacola .btn-account,
      .body-admin body .nav-cocacola .btn-account {
        background-color: #de1f27 !important;
        border-color: #fff;
        border-radius: 100%; }
    #root body .p-setting-account .img-account,
    html body .p-setting-account .img-account,
    body body .p-setting-account .img-account,
    .body-admin body .p-setting-account .img-account {
      width: 100%;
      object-fit: contain; }
    #root body .p-fieldset .p-fieldset-legend,
    html body .p-fieldset .p-fieldset-legend,
    body body .p-fieldset .p-fieldset-legend,
    .body-admin body .p-fieldset .p-fieldset-legend {
      border: unset;
      background: unset;
      font-size: 14px;
      margin-left: 10px;
      width: auto; }
    #root body legend,
    html body legend,
    body body legend,
    .body-admin body legend {
      margin-bottom: -1.5rem;
      text-transform: uppercase; }
    #root body .p-datatable .p-datatable-thead > tr > th,
    html body .p-datatable .p-datatable-thead > tr > th,
    body body .p-datatable .p-datatable-thead > tr > th,
    .body-admin body .p-datatable .p-datatable-thead > tr > th {
      border: 1px solid #dee2e6;
      padding: 5px; }
    #root body .p-datatable .p-datatable-tbody > tr > td,
    html body .p-datatable .p-datatable-tbody > tr > td,
    body body .p-datatable .p-datatable-tbody > tr > td,
    .body-admin body .p-datatable .p-datatable-tbody > tr > td {
      border: 1px solid #dee2e6;
      padding: 5px; }
      #root body .p-datatable .p-datatable-tbody > tr > td .p-col-fixed,
      html body .p-datatable .p-datatable-tbody > tr > td .p-col-fixed,
      body body .p-datatable .p-datatable-tbody > tr > td .p-col-fixed,
      .body-admin body .p-datatable .p-datatable-tbody > tr > td .p-col-fixed {
        padding: 0 !important; }
    #root body .p-datatable .p-datatable-tbody > tr:nth-child(2n),
    html body .p-datatable .p-datatable-tbody > tr:nth-child(2n),
    body body .p-datatable .p-datatable-tbody > tr:nth-child(2n),
    .body-admin body .p-datatable .p-datatable-tbody > tr:nth-child(2n) {
      background-color: #f9f9f9; }
    #root body .p-button-label,
    html body .p-button-label,
    body body .p-button-label,
    .body-admin body .p-button-label {
      text-transform: uppercase; }
    #root body .event-name,
    html body .event-name,
    body body .event-name,
    .body-admin body .event-name {
      font-size: 15px;
      font-weight: 500;
      color: #dd1f26; }
    #root body .event-desc,
    html body .event-desc,
    body body .event-desc,
    .body-admin body .event-desc {
      height: 25px;
      overflow: hidden;
      white-space: nowrap;
      /* Don't forget this one */
      text-overflow: ellipsis; }
    #root body .info-code,
    html body .info-code,
    body body .info-code,
    .body-admin body .info-code {
      color: #666666; }
    #root body .p-card .p-card-body,
    html body .p-card .p-card-body,
    body body .p-card .p-card-body,
    .body-admin body .p-card .p-card-body {
      padding: 0.5rem; }
    #root body .p-card .p-card-content,
    html body .p-card .p-card-content,
    body body .p-card .p-card-content,
    .body-admin body .p-card .p-card-content {
      padding: 0.25rem 0; }
    #root body .filter-dropdown + .p-dropdown-panel,
    html body .filter-dropdown + .p-dropdown-panel,
    body body .filter-dropdown + .p-dropdown-panel,
    .body-admin body .filter-dropdown + .p-dropdown-panel {
      position: relative; }
      #root body .filter-dropdown + .p-dropdown-panel .p-dropdown-panel,
      html body .filter-dropdown + .p-dropdown-panel .p-dropdown-panel,
      body body .filter-dropdown + .p-dropdown-panel .p-dropdown-panel,
      .body-admin body .filter-dropdown + .p-dropdown-panel .p-dropdown-panel {
        left: unset !important;
        right: 0 !important; }

label {
  margin-bottom: 0 !important; }

.p-radiobutton-cus {
  margin-left: 10px; }

.swal2-container {
  z-index: 999999 !important; }
