.react-datetime-picker__wrapper {
    width: 100% !important
}

.lightbox-container {
    /*flex: 0 0 100%;
    max-width: 100%;*/
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
    width: 100% !important;
}
.lightbox-padding{
    margin:15px;
}
.lightbox-btn {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    background: none;
    border: 1px solid transparent;
    fill: #fff;
    opacity: .8;
    outline: none;
    margin: 2px;
    padding: 0;
    -webkit-tap-highlight-color: #ffffff
}

    .lightbox-btn:hover {
        opacity: 1;
        -webkit-transition: opacity .2s ease;
        -o-transition: opacity .2s ease;
        transition: opacity .2s ease
    }

.lightbox-btn--ripple circle {
    fill: none
}

.lightbox-btn--ripple:hover circle {
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    -webkit-transform-origin: 33% 33%;
    -ms-transform-origin: 33% 33%;
    transform-origin: 33% 33%;
    fill: #64646480;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease,-webkit-transform .2s ease
}

.lightbox-content-center {
    width: 100%;
    height: 100%;
    position: absolute
}

.lightbox-modifiers-box {
    background-color: rgba(0,0,0,.2);
    text-align: center;
    font-size: 1.2em;
    position: absolute;
    width: 100%;
    z-index: 1
}

@media (min-height:600px) {
    .lightbox-modifiers-box {
        background-color: transparent
    }
}

@media (min-width:768px) {
    .lightbox-image-container {
        margin: 0 5em
    }
}

.lightbox-image-container {
    overflow: hidden;
    height: calc(100% - 102px);
    margin-top: 40px
}

.lightbox-image {
    height: 100%;
    background-repeat: no-repeat
}

    .lightbox-image.moving {
        -webkit-transition: none;
        -o-transition: none;
        transition: none
    }

.lightbox-loader {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    fill: #fff;
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn)
    }
}

@keyframes rotating {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn)
    }
}

html.lightbox-open {
    overflow: hidden;
    position: fixed;
    width: 100%
}

.lightbox-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    z-index: 100
}

.lightbox-btn-close, .lightbox-btn-left, .lightbox-btn-right, .lightbox-modifiers-box, .lightbox-title-content {
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease
}

@media (max-width:767px) {
    .hide-controls .lightbox-btn-left, .hide-controls .lightbox-btn-right {
        opacity: 0;
        pointer-events: none
    }
}

@media (max-height:599px) {
    .hide-controls .lightbox-btn-close, .hide-controls .lightbox-modifiers-box, .hide-controls .lightbox-title-content {
        opacity: 0;
        pointer-events: none
    }
}

.lightbox-btn-close {
    position: fixed;
    left: 0;
    z-index: 2
}

.lightbox-btn-right {
    right: 0
}

.lightbox-btn-left, .lightbox-btn-right {
    position: absolute;
    top: 50%;
    margin-top: -1.5em
}

.lightbox-btn-left {
    left: 0
}

@media (min-width:768px) {
    .lightbox-btn-right {
        padding-right: 10px
    }

    .lightbox-btn-left {
        padding-left: 10px
    }
}

.lightbox-title-content {
    background-color: rgba(0,0,0,.2);
    -webkit-box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
    text-align: center;
    padding: 10px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    line-height: 1.42857143
}

.lightbox-title {
    color: #fff;
    font-weight: 600;
    font-size: 16px
}

.lightbox-description {
    color: #c5c5c5;
    font-size: 14px
}

.lightbox-transition-image-appear {
    opacity: .1
}

    .lightbox-transition-image-appear.lightbox-transition-image-appear-active {
        opacity: 1;
        -webkit-transition: opacity .3s ease-in;
        -o-transition: opacity .3s ease-in;
        transition: opacity .3s ease-in
    }

.lightbox-transition-image-enter-right {
    -webkit-transform: translate(100%);
    -ms-transform: translate(100%);
    transform: translate(100%)
}

    .lightbox-transition-image-enter-right.lightbox-transition-image-enter-right-active {
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        transform: translate(0);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }

.lightbox-transition-image-leave-right {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0)
}

    .lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }

    .lightbox-transition-image-enter-left, .lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
        -webkit-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%)
    }

        .lightbox-transition-image-enter-left.lightbox-transition-image-enter-left-active {
            -webkit-transform: translate(0);
            -ms-transform: translate(0);
            transform: translate(0);
            -webkit-transition: -webkit-transform .3s ease-in-out;
            transition: -webkit-transform .3s ease-in-out;
            -o-transition: transform .3s ease-in-out;
            transition: transform .3s ease-in-out;
            transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
        }

.lightbox-transition-image-leave-left {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0)
}

    .lightbox-transition-image-leave-left.lightbox-transition-image-leave-left-active {
        -webkit-transform: translate(100%);
        -ms-transform: translate(100%);
        transform: translate(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }

.lightbox-img-thumbnail {
    -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 2px;
    margin: 10px;
    cursor: pointer
}

@media (min-width:768px) {
    .lightbox-img-thumbnail:hover {
        -webkit-box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
        box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
        -webkit-transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
        -o-transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
        transition: all .45s cubic-bezier(.23,1,.32,1) 0ms
    }
}
/*# sourceMappingURL=index.css.map*/


* {
    box-sizing: border-box
}

.datetime-picker {
    position: relative;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    font-family: Tahoma,sans-serif;
    font-size: 14px;
    color: #4a4a4a;
    width: 250px;
    box-sizing: content-box;
    z-index: 100
}

    .datetime-picker .calendar .calendar-nav {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        min-height: 32px;
        padding: 4px
    }

        .datetime-picker .calendar .calendar-nav button {
            background: none;
            border: 0;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            outline: none;
            cursor: pointer
        }

            .datetime-picker .calendar .calendar-nav button .fa {
                font-size: 18px
            }

            .datetime-picker .calendar .calendar-nav button:hover {
                background-color: #f0f0f0
            }

        .datetime-picker .calendar .calendar-nav .current-date {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 0 8px;
            cursor: pointer
        }

            .datetime-picker .calendar .calendar-nav .current-date:hover {
                background-color: #f0f0f0
            }

            .datetime-picker .calendar .calendar-nav .current-date.disabled {
                cursor: default
            }

    .datetime-picker .calendar table {
        display: block;
        margin: 4px
    }

        .datetime-picker .calendar table td, .datetime-picker .calendar table th {
            padding: 0
        }

        .datetime-picker .calendar table thead {
            display: block;
            margin: 8px 0 3px
        }

            .datetime-picker .calendar table thead tr {
                display: flex;
                flex-wrap: wrap
            }

            .datetime-picker .calendar table thead th {
                color: #ff9a19;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                text-align: center;
                text-transform: uppercase;
                font-size: .8em;
                font-weight: 400
            }

        .datetime-picker .calendar table tbody {
            display: block
        }

            .datetime-picker .calendar table tbody tr {
                display: flex;
                flex-wrap: wrap
            }

                .datetime-picker .calendar table tbody tr td {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    border-radius: 4px;
                    cursor: pointer
                }

                    .datetime-picker .calendar table tbody tr td:hover {
                        background-color: #f0f0f0
                    }

                    .datetime-picker .calendar table tbody tr td.disabled, .datetime-picker .calendar table tbody tr td.next, .datetime-picker .calendar table tbody tr td.prev {
                        color: #dedede
                    }

                        .datetime-picker .calendar table tbody tr td.disabled:hover, .datetime-picker .calendar table tbody tr td.next:hover, .datetime-picker .calendar table tbody tr td.prev:hover {
                            color: #c5c5c5
                        }

                        .datetime-picker .calendar table tbody tr td.disabled:hover {
                            color: #dedede;
                            background-color: transparent;
                            cursor: not-allowed
                        }

                    .datetime-picker .calendar table tbody tr td.now {
                        color: #5cc4ef;
                        font-weight: 400
                    }

                    .datetime-picker .calendar table tbody tr td.selected {
                        background-color: #5cc4ef;
                        color: #fff;
                        font-weight: 400
                    }

                        .datetime-picker .calendar table tbody tr td.selected:hover {
                            background-color: #53c1ee;
                            color: #fff
                        }

                        .datetime-picker .calendar table tbody tr td.selected.start {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0
                        }

                        .datetime-picker .calendar table tbody tr td.selected.end {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0
                        }

                        .datetime-picker .calendar table tbody tr td.selected.start.end {
                            border-radius: 4px
                        }

                    .datetime-picker .calendar table tbody tr td.between {
                        background-color: #f5fbfe;
                        border-radius: 0
                    }

    .datetime-picker .calendar .calendar-days table tbody tr td {
        width: 14.28571429%
    }

    .datetime-picker .calendar .calendar-months table tbody tr td {
        width: 33.33333333%;
        height: 40px
    }

    .datetime-picker .calendar .calendar-years table tbody tr td {
        width: 25%;
        height: 60px
    }

    .datetime-picker .time {
        border-top: 1px solid #efefef;
        padding: 4px;
        display: flex;
        align-items: center;
        position: relative
    }

        .datetime-picker .time .show-time {
            display: flex;
            align-items: center;
            flex: 1;
            font-size: 14px;
            text-align: center;
            margin: 0 0 0 10px
        }

            .datetime-picker .time .show-time .text {
                line-height: 1;
                font-size: 19px;
                font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
                position: relative;
                z-index: 1
            }

            .datetime-picker .time .show-time .separater {
                margin: 0 2px 3px;
                line-height: 1
            }

        .datetime-picker .time .sliders {
            flex: 0 1 153px;
            margin-right: 10px;
            max-width: 153px
        }

            .datetime-picker .time .sliders .slider-text {
                display: none
            }

            .datetime-picker .time .sliders .slider {
                display: flex;
                align-items: center;
                font-size: 11px;
                height: 17px;
                background-image: linear-gradient(90deg,#dedede,#dedede);
                background-position: 0 50%;
                background-size: 100% 1px;
                background-repeat: no-repeat
            }

                .datetime-picker .time .sliders .slider .handle {
                    width: 12px;
                    height: 12px;
                    background-color: #fff;
                    border: 1px solid #dedede;
                    border-radius: 3px;
                    cursor: pointer
                }

                    .datetime-picker .time .sliders .slider .handle:hover {
                        border-color: #b8b8b8
                    }

                    .datetime-picker .time .sliders .slider .handle:active, .datetime-picker .time .sliders .slider .handle:focus {
                        background-color: #5cc4ef;
                        border-color: #5cc4ef
                    }

    .datetime-picker .shortcuts-bar {
        border-bottom: 1px solid #efefef;
        padding: 8px
    }

        .datetime-picker .shortcuts-bar .btn {
            border: 0;
            background: none;
            cursor: pointer;
            border-radius: 2px;
            padding: 2px 4px;
            outline: none
        }

            .datetime-picker .shortcuts-bar .btn:hover {
                background-color: #f0f0f0
            }

            .datetime-picker .shortcuts-bar .btn:last-child {
                float: right
            }

            .datetime-picker .shortcuts-bar .btn:not(:first-child) {
                margin-left: 5px
            }

.datetime-range-picker {
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: content-box;
    z-index: 100
}

.datetime-trigger {
    position: relative
}

    .datetime-trigger .datetime-picker {
        position: absolute;
        top: 100%
    }

.datetime-range-trigger {
    position: relative
}

    .datetime-range-trigger .datetime-range-picker {
        position: absolute;
        top: 100%
    }

.datetime-picker-popup, .datetime-range-picker-popup {
    margin-top: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,.15)
}

    .datetime-picker-popup:before, .datetime-range-picker-popup:before {
        content: "";
        position: absolute;
        background: #fff;
        border-top: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        width: 10px;
        height: 10px;
        z-index: -1;
        left: 10px;
        top: -6px;
        transform: rotate(315deg)
    }

body {
    margin: 0;
    padding: 0;
    font-family: Tahoma,sans-serif;
    font-size: 14px;
    color: #333
}

pre {
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    margin: 16px 0;
    font-size: 13px
}

code {
    font-family: Consolas,monospace;
    line-height: 1.2
}

.title {
    text-align: center;
    font-weight: 100;
    text-transform: uppercase
}

    .title .buttons {
        display: inline-block
    }

.content {
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 50px
}

.caption, .heading {
    font-family: Fira Sans,sans-serif;
    font-weight: 100;
    line-height: 1
}

.heading {
    font-size: 26px;
    margin-top: 50px
}

.caption {
    font-size: 18px;
    margin-top: 15px
}

.example-code:after {
    content: "";
    display: table;
    clear: both
}

.example-code pre {
    float: left;
    width: 650px
}

.example-code .example {
    margin-left: 650px;
    text-align: center;
    padding-top: 50px
}

    .example-code .example #inline-picker, .example-code .example #popup-picker {
        display: inline-block
    }

        .example-code .example #inline-picker .text {
            display: block;
            text-align: left;
            padding: 10px 0
        }

        .example-code .example #popup-picker .datetime-trigger {
            text-align: left;
            cursor: pointer
        }

        .example-code .example #popup-picker input {
            height: 30px;
            padding: 0 8px;
            border: 1px solid #ddd
        }

        .example-code .example #popup-picker .fa-calendar-o {
            background-color: #f5f5f5;
            border: 1px solid #ddd;
            height: 30px;
            line-height: 30px;
            padding: 0 8px;
            margin-left: -1px;
            vertical-align: middle
        }